<footer class="bg-general-1">
	<div class="container px-0 px-sm-3">
		<div class="row mx-0 align-items-start px-xl-3 justify-content-between">
			<div class="col-4 px-0">
				<a class="brand-name" href="/" ng-reflect-router-link="/">{{ 'n3786' | translations }}</a>

				<span class="d-block fs-14px fw-400 lh-18px fg-general-3 my-2">{{ 'n3158' | translations }}</span>

				<span class="d-block fs-14px fw-400 lh-18px fg-general-3 mb-2">&copy; {{ currentYear }} {{ 'n3472' | translations }}</span>

				<img src="assets/images/dbi-finalists-2023.png" alt="dbi-finalists-2023" width="90" height="78" />
			</div>
			<div class="col-auto px-0">
				<span class="fg-general-5 fs-14px lh-18px fw-400">{{ 'n627' | translations }}</span>

				<ng-container *ngFor="let socialmedia of (socialLinks$ | async)?.socialLinks">
					<a [href]="socialmedia?.url" target="_blank" class="social-icons d-flex align-items-center gap-1">
						@if (socialmedia?.name.toLowerCase() === 'facebook') {
							<findqo-facebook-icon></findqo-facebook-icon>
						}

						@if (socialmedia?.name.toLowerCase() === 'instagram') {
							<findqo-instagram-icon></findqo-instagram-icon>
						}

						@if (socialmedia?.name.toLowerCase() === 'linkedin') {
							<findqo-linkedin-icon></findqo-linkedin-icon>
						}

						@if (socialmedia?.name.toLowerCase() === 'youtube') {
							<div class="ms-1">
								<findqo-youtube-icon></findqo-youtube-icon>
							</div>
						}
						{{ socialmedia?.name }}
					</a>
				</ng-container>
			</div>

			<div class="col-auto px-0">
				<span class="fg-general-5 fs-14px lh-18px fw-400">{{ 'n635' | translations }}</span>

				<a *ngFor="let nav of compantPolicyNavList" [routerLink]="nav.path">
					<span class="fg-general-3 fs-14px lh-18px fw-400 mt-2 d-block">{{ nav.data.title | translations }}</span>
				</a>
			</div>

			<div class="col-auto px-0">
				<span class="fg-general-5 fs-14px lh-18px fw-400">{{ 'n636' | translations }}</span>

				<a href="mailto:hello@findqo.ie" class="social-icons">
					<mat-icon class="fs-20px">mail_outline</mat-icon>
					<ng-container *ngIf="isPlatformBrowser">
						{{ 'n2316' | translations }}
					</ng-container>
				</a>

				<a class="social-icons" [href]="feedbackUrl" target="_blank">
					<mat-icon class="material-symbols-outlined icon-size">feedback</mat-icon>
					{{ 'n3278' | translations }}
				</a>

				<a class="social-icons" [routerLink]="appRoute.contact">
					<mat-icon class="material-symbols-outlined icon-size">contact_support</mat-icon>
					<ng-container *ngIf="isPlatformBrowser">
						{{ 'n60' | translations }}
					</ng-container>
				</a>

				<a class="social-icons" [routerLink]="appRoute.placeAdGuide">
					<mat-icon class="material-symbols-outlined icon-size">help</mat-icon>
					<ng-container *ngIf="isPlatformBrowser">
						{{ 'n3761' | translations }}
					</ng-container>
				</a>
			</div>

			<div class="col-auto px-0 text-center">
				<span class="fg-general-5 fs-14px lh-18px fw-400">{{ 'n638' | translations }}</span>
				<img class="img-fluid d-block mx-auto mt-2" src="/assets/images/app-qr-code-ireland.svg" alt="QR code to download app" />
			</div>
		</div>
	</div>
</footer>
