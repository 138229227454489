import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants/route.constant';
import { ViewportService } from '@core/services/viewport.service';

@Component({
	selector: 'findqo-sticky-promotion-header',
	templateUrl: './sticky-promotion-header.component.html',
	styleUrl: './sticky-promotion-header.component.scss',
})
export class StickyPromotionHeaderComponent implements OnInit {
	constructor(
		private router: Router,
		public viewPort: ViewportService,
		@Inject(PLATFORM_ID) private platformId: string
	) {}

	showPromotionAd = false;

	isBrowser = isPlatformBrowser(this.platformId);

	onNavigate() {
		this.router.navigate(['/' + APP_ROUTE.propertyWorth]);
	}

	ngOnInit(): void {
		this.router.events.subscribe(() => {
			// Check the current route

			if (this.router.url.includes('free-property-valuation')) {
				this.showPromotionAd = false; // Hide the ad
			} else {
				this.showPromotionAd = true; // Show the ad
			}
		});
	}
}
