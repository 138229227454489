<ng-container
	*ngIf="{
		user: user$ | async,
		routeData: routeData$ | async,
		sectionList: sectionList$ | async
	} as vm"
>
	<div class="container mt-1">
		<div class="row">
			<mat-toolbar class="bg-basic-1">
				<mat-toolbar-row class="p-0">
					<ng-container class="justify-content-center justify-content-lg-between">
						<div class="col d-block d-lg-none">
							<mat-icon (click)="onOpenHeaderSidenav()" class="material-icons-outlined bg-basic-1 fg-basic-2">menu</mat-icon>
						</div>

						<div [class.w-100]="!viewport?.desktop" [class.justify-content-center]="!viewport?.desktop" class="row align-items-center gap-3 h-100">
							<div class="col-auto">
								<a [ngClass]="[viewport.desktop ? 'brand-name' : 'brand-name mob-header']" [routerLink]="[appRoute.home]">{{ 'n3786' | translations }}</a>
							</div>

							<div class="col-auto h-100 d-none d-lg-block">
								<findqo-header-nav-items [sectionList]="vm.sectionList" [userData]="vm.user" [routeData]="vm.routeData"></findqo-header-nav-items>
							</div>
						</div>

						<div class="ms-auto d-none d-lg-block" *ngIf="!vm?.routeData?.isAgentLandingPage">
							<div class="row align-items-center justify-content-between">
								<!-- Switch to agent hub-->
								<div class="col-auto me-2 me-sm-4" *ngIf="+vm.user?.hasBusinessProfile">
									<findqo-button
										[label]="'n4075' | translations"
										[btnType]="buttonType.FILLED"
										[routerLink]="'/' + appRoute.agentHub.dashboard"
										materialIconPrefix="autorenew"
										btnClass="rounded-pill"
									></findqo-button>
								</div>

								<!-- Place ad -->
								<div class="col-auto me-2 me-sm-3" *ngIf="!+vm.user?.hasBusinessProfile">
									<findqo-button [label]="'n3474' | translations" [btnType]="buttonType.FILLED" (clicked)="goToPlaceAd()"></findqo-button>
								</div>

								<!-- Header login or account menu -->
								<div class="col-auto">
									<ng-template [ngTemplateOutlet]="vm.user ? headerAccountMenuTemplate : btnLoginTemplate"></ng-template>
								</div>
							</div>
						</div>
					</ng-container>
					<div>
						<findqo-button
							*ngIf="!isFilterDialog && !viewport.desktop && !containerClass.includes('home-page')"
							btnClass="text-caption-regular h-100 phone-primary py-1 border-none"
							[label]="'ALERT ME'"
							[btnType]="buttonType.BASIC"
							btnIconClass="material-symbols-outlined m-0 fs-14px"
							materialIconPrefix="notifications"
							[isStopPropagation]="true"
							(clicked)="onShowAlertsDialog(vm.routeData)"
						></findqo-button>
					</div>
				</mat-toolbar-row>
			</mat-toolbar>
		</div>
	</div>

	<!-- Header login -->
	<ng-template #btnLoginTemplate>
		<findqo-button btnClass="fg-general-4" [btnType]="buttonType.BASIC" [label]="'n11' | translations" (clicked)="onLogin()"></findqo-button>
	</ng-template>

	<!-- Header account menu -->
	<ng-template #headerAccountMenuTemplate>
		<findqo-header-account-menu></findqo-header-account-menu>
	</ng-template>
</ng-container>
