<div class="container p-0">
	<div class="row mx-0 px-3 justify-content-between align-items-center dialog-header">
		<div class="col-auto px-0">
			<h4 class="heading-4 fg-general-5 m-0">Property Valuation in Progress</h4>
		</div>
	</div>

	<div class="px-3 pt-3">
		<p class="text-body-regular fg-general-5 mb-1">If you leave now, your property valuation won’t be completed, and you'll have to start over.</p>
	</div>

	<div class="p-3">
		<p class="text-body-regular fg-general-4 mb-3">Do you still want to leave this page?</p>
	</div>

	<div class="row mx-0 gap-3 p-3 dialog-actions">
		<div class="col px-0">
			<findqo-button
				[btnType]="buttonType.BASIC"
				[label]="'Leave Page'"
				[isStopPropagation]="true"
				[btnClass]="'text-body-regular w-100 delete-dialog-btn'"
				(clicked)="onLeftButton()"
			></findqo-button>
		</div>

		<div class="col px-0">
			<findqo-button
				[btnType]="buttonType.FILLED"
				[label]="'Stay on Page'"
				[isStopPropagation]="true"
				[btnClass]="'text-body-regular w-100 delete-dialog-btn'"
				(clicked)="onRightButton()"
			></findqo-button>
		</div>
	</div>
</div>
