<div class="container bg-basic-1">
	<mat-toolbar [ngClass]="!viewport.desktop ? 'justify-content-center' : 'justify-content-between'" class="bg-basic-1 header px-0">
		<div class="position-absolute start-0 ps-3 d-block d-lg-none">
			<mat-icon (click)="onOpenHeaderSidenav()" class="material-icons-outlined bg-basic-1 fg-basic-2">menu</mat-icon>
		</div>
		<div>
			<span [ngClass]="viewport.desktop ? 'fs-32px' : 'fs-26px'" class="fg-general-5 fw-700 lh-36px me-3 cursor-pointer align-middle" (click)="navigateAway()">
				{{ 'n3786' | translations }}
			</span>
		</div>

		<div class="align-self-center d-none d-md-block">
			<findqo-button
				id="intercom-chat"
				class="fg-general-4"
				[btnType]="buttonType.BASIC"
				[label]="'n3073' | translations"
				[iconPrefix]="'help-outline'"
				[btnClass]="'text-body-regular fg-general-4 me-2'"
				(clicked)="showIntercom()"
			></findqo-button>
		</div>
	</mat-toolbar>
</div>
<mat-divider></mat-divider>
