import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IconType } from '@core/enums';
import { ButtonType } from '@shared/components/button/button.component';

@Component({
	selector: 'findqo-restrict-user-dialog',
	templateUrl: './restrict-user-dialog.component.html',
	styleUrls: ['./restrict-user-dialog.component.scss'],
	providers: [],
})
export class RestrictUserDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<RestrictUserDialogComponent>
	) {}

	readonly iconType = IconType;
	readonly buttonType = ButtonType;

	onLeftButton(): void {
		this.dialogRef.close(false);
	}

	onRightButton(): void {
		this.dialogRef.close(true);
	}
}
