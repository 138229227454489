import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { Event, NavigationStart, Router } from '@angular/router';
import { ConfigApi } from '@core/data-access/apis/config.api';
import { AuthService } from '@core/data-access/services/auth.service';
import { ConfigState } from '@core/data-access/states/config.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import { AuthType, Breakpoint, LocaleType, StorageType } from '@core/enums';
import { Config, Translations } from '@core/models';
import { BreakpointObserverService } from '@core/services/breakpoint-observer.service';
import { CanonicalService } from '@core/services/canonical.service';
import { MetaPixelService } from '@core/services/meta-pixel.service';
import { StorageService } from '@core/services/storage.service';
import { ThemeService } from '@core/services/theme.service';
import { ViewportService } from '@core/services/viewport.service';
import { environment } from '@environments/environment';
import { filter, lastValueFrom, of, switchMap, takeUntil, tap, timer } from 'rxjs';
import * as sections from '../../shared/json/sections/sections.json';
import { AuthDialogV2Component } from '@feature/auth/v2/auth-dialog-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { DestroyService } from '@shared/services/destroy.service';
import { SKIP_SURVEY_DURATION } from '@core/constants/user-preference.constant';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import * as Sentry from '@sentry/angular';

/**
 * Provide the factory function in the index.ts of initializers folder.
 * We can have a separate file for every initializer if needed.
 */

export const initThemeFactory = (themeService: ThemeService) => {
	return () => {
		themeService.init();
	};
};

export const initBreakpointObserverViewportFactory = (viewportService: ViewportService, bpoService: BreakpointObserverService) => {
	return () => {
		bpoService.init();

		bpoService.breakpoints$.subscribe((breakpoints) => {
			if (breakpoints[Breakpoint.LG]) {
				viewportService.set('desktop');
			} else if (breakpoints[Breakpoint.SM]) {
				viewportService.set('tablet');
			} else if (breakpoints[Breakpoint.XS]) {
				viewportService.set('mobile');
			}
		});
	};
};

export const initProfileStateFactory = (profilefacade: MyProfileFacade, platformId: string, auth: AuthService) => {
	return () => {
		const user = auth.user();

		if (isPlatformBrowser(platformId) && user) {
			profilefacade.loadUser();

			if (user?.email) {
				Sentry.setUser({
					id: user.id,
					email: user.email,
				});
			}
		}
	};
};

export const initSectionListFactory = (sectionsState: SectionsState) => {
	return () => {
		sectionsState.setSectionList(sections.data);
	};
};

/**
 * Init the configuration of the app
 */
export const initConfigFactory = (
	configApi: ConfigApi,
	configState: ConfigState,
	storageService: StorageService,
	platformId: string,
	dialog: MatDialog,
	destroyService: DestroyService,
	authService: AuthService
) => {
	return async () => {
		/**
		 * HACK: Load the static translations saved in the constant file while waiting for the Translations API response.
		 * Translations API response time is very slow and affecting the initialization of the APP.
		 */

		await lastValueFrom(configApi.getJsonTranslation()).then((translations: Translations) => configState.setTranslations(translations));

		if (isPlatformBrowser(platformId)) {
			configApi
				.getConfig()
				.pipe(
					switchMap((config: Config) => {
						const PREV_TIME = +storageService.getItem(StorageType.SURVEY_SHOWN_AT);
						const CURRENT_TIME = new Date().getTime();

						const IS_TIME_EXCEEDED = CURRENT_TIME > PREV_TIME + SKIP_SURVEY_DURATION;
						const SURVEY_COUNT = +storageService.getItem(StorageType.SURVEY_COUNT);

						// Show interest survey dialog to already loggedIn user, 3sec after initial load
						if (
							!+config.isSurveySubmitted &&
							!+storageService.getItem(StorageType.IGNORE_SURVEY) &&
							!!authService.user() &&
							IS_TIME_EXCEEDED &&
							SURVEY_COUNT < 3
						) {
							timer(3000)
								.pipe(
									takeUntil(destroyService),
									tap(() => {
										dialog.open(AuthDialogV2Component, {
											panelClass: 'survey-dialog',
											disableClose: true,
											data: { authType: AuthType.INTEREST_SURVEY },
										});
									})
								)
								.subscribe();
						}

						const TRANSLATIONS = storageService.getItem(StorageType.TRANSLATIONS);
						const TRANSLATIONS_UPDATED_AT = storageService.getItem(StorageType.TRANSLATIONS_UPDATED_AT);
						const LOCALE = storageService.getItem(StorageType.LOCALE);
						storageService.setItem(StorageType.TRANSLATIONS_UPDATED_AT, config.translation.updatedAt);
						configState.setConfig(config);
						// Return initial translations if platform is server
						if (isPlatformServer(platformId)) {
							return of(configState.getTranslations());
						}
						// Update cached translations if necessary, otherwise reuse cached translations
						if (config.translation.updatedAt >= TRANSLATIONS_UPDATED_AT) {
							return configApi.getTranslations(LOCALE || LocaleType.ENGLISH);
						} else {
							return of(TRANSLATIONS ? JSON.parse(TRANSLATIONS) : undefined);
						}
					}),
					filter((translations: Translations | undefined): translations is Translations => !!translations)
				)
				.subscribe((translations: Translations) => {
					storageService.setItem(StorageType.TRANSLATIONS, JSON.stringify(translations));
					configState.setTranslations(translations);
				});
		}
	};
};

/**
 * SEO
 */
export const initSeoFactory = (router: Router, canonicalService: CanonicalService, metaService: Meta) => {
	return () => {
		/**
		 * Remove the previous canonical URL and robots meta tag as we just need one per page.
		 * We only adding canonical URL and robots meta tag using the Angular Guard.
		 * Only production environment needed to be indexed.
		 */
		const handleCanonicalUrl = (): void => {
			if (environment.production) {
				canonicalService.removeCanonicalLinkElement();
			}
		};

		const handleRobotsMetaTag = (): void => {
			metaService.removeTag('name="robots"');

			if (!environment.production) {
				metaService.addTag({
					name: 'robots',
					content: 'noindex, nofollow',
				});
			}
		};

		router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				handleCanonicalUrl();
				handleRobotsMetaTag();
			}
		});
	};
};

export const initializeMetaPixelFactory = (metaPixelService: MetaPixelService, platformId: string) => {
	return () => {
		if (environment.production && isPlatformBrowser(platformId)) {
			metaPixelService.initializeMetaPixel(environment.metaPixelId);
		}
	};
};
