<footer class="bg-basic-1 py-4">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-auto">
				<h4 class="d-flex heading-4 fg-general-5 me-0 me-md-1">
					{{ 'n3870' | translations }}
				</h4>
			</div>

			<div class="col">
				<button mat-button [matMenuTriggerFor]="menu" color="primary" class="border-bottom d-none d-md-flex" disableRipple>
					{{ selectedSection | sectionText }}
					<mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
				</button>

				<button mat-button (click)="openBottomSheet()" color="primary" class="border-bottom d-md-none" disableRipple>
					{{ selectedSection | sectionText }}
					<mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
				</button>
			</div>
		</div>

		<div class="pt-3 border-top">
			<findqo-area-list
				[selectedSection]="sectionEnum.RENT_RESIDENTIAL"
				[ngClass]="selectedSection === sectionEnum.RENT_RESIDENTIAL ? 'd-block' : 'd-none'"
			></findqo-area-list>

			<findqo-area-list
				[selectedSection]="sectionEnum.SHARE_RESIDENTIAL"
				[ngClass]="selectedSection === sectionEnum.SHARE_RESIDENTIAL ? 'd-block' : 'd-none'"
			></findqo-area-list>

			<findqo-area-list
				[selectedSection]="sectionEnum.SELL_RESIDENTIAL"
				[ngClass]="selectedSection === sectionEnum.SELL_RESIDENTIAL ? 'd-block' : 'd-none'"
			></findqo-area-list>
		</div>
	</div>
</footer>

<mat-menu #menu="matMenu">
	<ng-container
		*ngTemplateOutlet="
			menuItem;
			context: { $implicit: { name: 'Rent', isSelected: selectedSection === sectionEnum.RENT_RESIDENTIAL, reference: sectionEnum.RENT_RESIDENTIAL } }
		"
	></ng-container>

	<ng-container
		*ngTemplateOutlet="
			menuItem;
			context: { $implicit: { name: 'Share', isSelected: selectedSection === sectionEnum.SHARE_RESIDENTIAL, reference: sectionEnum.SHARE_RESIDENTIAL } }
		"
	></ng-container>

	<ng-container
		*ngTemplateOutlet="
			menuItem;
			context: { $implicit: { name: 'Sale', isSelected: selectedSection === sectionEnum.SELL_RESIDENTIAL, reference: sectionEnum.SELL_RESIDENTIAL } }
		"
	></ng-container>
</mat-menu>

<ng-template #menuItem let-item>
	<button mat-menu-item (click)="onSectionChange(item.reference)">
		<span class="d-flex d-inline-block w-100">
			{{ item?.name }}
			<mat-icon class="fg-prim-2 me-0 ms-3" iconPositionEnd *ngIf="item?.isSelected">check</mat-icon>
		</span>
	</button>
</ng-template>
