import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { APP_ROUTE } from '@core/constants';
import { CommonFacade } from '@core/facades/common.facade';
import { Config } from '@core/models';
import { ButtonType } from '@shared/components/button/button.component';
import { Observable } from 'rxjs';
import { COMPANY_POLICY_LIST } from '../../footer.route';
import { environment } from '@environments/environment';

@Component({
	selector: 'findqo-footer-mobile',
	templateUrl: './footer-mobile.component.html',
	styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent {
	constructor(
		private readonly commonFacade: CommonFacade,
		@Inject(PLATFORM_ID) private readonly platformId: string
	) {
		this.socialLinks$ = this.commonFacade.getConfig$();
	}

	readonly compantPolicyNavList = COMPANY_POLICY_LIST;
	readonly buttonType = ButtonType;
	readonly appRoute = APP_ROUTE;
	readonly isPlatformBrowser = isPlatformBrowser(this.platformId);

	currentYear: number = new Date().getFullYear();
	socialLinks$: Observable<Config>;
	feedbackUrl = environment.feedbackUrl;
}
