import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';

import { provideHttpClient } from '@angular/common/http';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(BrowserModule, AppRoutingModule, CoreModule),
		provideClientHydration(),
		provideAnimations(),
		provideHttpClient(),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler(),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
};
