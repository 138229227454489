import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@core/data-access/services/auth.service';
import { RouteService } from '@core/services/route.service';
import { PostAdDialogComponent } from '@shared/components/post-ad-dialog/post-ad-dialog.component';
import { AbstractHeaderComponent } from '../../abstracts/header.abstract';
import { ProfileDisabledDialogComponent } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.component';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { ClientUser } from '@core/models';
import { IntercomService } from '@core/services/intercom.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HeaderSidenavComponent } from '../../components/header-sidenav/header-sidenav.component';
import { OFFCANVAS_HEADER_SIDENAV } from '@core/constants/offcanvas.constant';
import { ViewportService } from '@core/services/viewport.service';
import { PropertyAlertDialogComponent } from '@feature/general/filters/components/property-alert-dialog/property-alert-dialog.component';

@Component({
	selector: 'findqo-header-v2',
	templateUrl: './header-v2.component.html',
	styleUrls: ['./header-v2.component.scss'],
})
export class HeaderV2Component extends AbstractHeaderComponent implements OnInit {
	constructor(
		private readonly routeService: RouteService,
		private authService: AuthService,
		private readonly userFacade: MyProfileFacade,
		private intercomService: IntercomService,
		private ngbOffCanvas: NgbOffcanvas,
		public viewport: ViewportService
	) {
		super();
	}
	@Input() containerClass = '';
	@Input() isFilterDialog = false;

	routeData$ = this.routeService.routeData$;

	goToPlaceAd(): void {
		const IS_LOGGED_IN = this.authService.user()?.accessToken;

		if (IS_LOGGED_IN) {
			this.userFacade.getProfile().subscribe((profile: ClientUser) => {
				const IS_ACCOUNT_DISABLED = +profile?.isDisabled;

				if (IS_ACCOUNT_DISABLED) {
					this.dialog.open(ProfileDisabledDialogComponent, {
						panelClass: 'post-ad-dialog',
					});

					return;
				}
			});

			this.openPostAdDialog();
		} else {
			this.authService.openAuthDialog({ onLogin: this.openPostAdDialog.bind(this), isPostAd: true });
		}
	}

	private openPostAdDialog() {
		return this.dialog.open(PostAdDialogComponent, {
			panelClass: 'post-ad-dialog',
		});
	}

	override ngOnInit(): void {
		super.ngOnInit();
	}
	onOpenHeaderSidenav(): void {
		this.intercomService.toggelIntercomPopup(true);
		this.ngbOffCanvas.open(HeaderSidenavComponent, OFFCANVAS_HEADER_SIDENAV);
	}

	onShowAlertsDialog(routeData) {
		if (!this.authService.user()) {
			this.authService.openAuthDialog();
			return;
		}

		this.dialog.open(PropertyAlertDialogComponent, {
			disableClose: true,
			panelClass: 'property-alert-dialog',
			data: {
				section: routeData?.['section'],
				aisle: routeData?.['aisle'],
				location: { ...(routeData?.location ? { countyReference: routeData?.location } : {}) },
			},
		});
	}
}
