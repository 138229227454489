import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error.interceptor';
import { HttpCacheInterceptor } from './http-cache.interceptor';
import { TokenInterceptor } from './token.interceptor';
import { RetryInterceptor } from './retry.interceptor';

export const APP_INTERCEPTORS = [
	{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
];
