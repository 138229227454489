<div class="container-fluid p-0">
	<findqo-header></findqo-header>
	<findqo-loader></findqo-loader>

	<main>
		<router-outlet></router-outlet>
	</main>

	<ng-container *ngIf="routeDataType.IS_FOOTER_VISIBLE | visible | async">
		<findqo-footer [isCountyListVisible]="isCountyListVisible"></findqo-footer>
	</ng-container>
</div>

<!-- TODO: Check if we still need this -->
<!-- <div hidden id="google-recaptcha-invisible"></div> -->
