<div *ngIf="showPromotionAd" class="home-sticky-ad bg-basic-2 w-100 p-0">
	<div
		[class.d-none]="!isBrowser"
		class="row gap-2 m-0 py-2 px-1 event-banner justify-content-sm-between justify-content-md-center align-items-center px-md-4 px-lg-5"
	>
		<!-- Notification Icon (Left Edge) -->
		<div class="col-auto pe-0">
			<div class="notification-ad-icon d-flex justify-content-center align-items-center">
				<mat-icon class="material-icons-outlined fg-basic-1">notifications</mat-icon>
			</div>
		</div>

		<!-- Banner Content (Centered) -->
		<div [ngClass]="viewPort.mobile ? 'col' : 'col-auto'" class="text-center">
			<h4 class="heading-4-regular fg-basic-1 fw-400 fs-16px text-wrap d-none d-md-block">
				Get a FREE Property Valuation in 2-minutes with FindQo.ie Property Valuation Calculator!
			</h4>
			<p class="fw-500 fs-10px lh-14px fg-basic-1 text-start text-wrap d-block d-md-none p-0 m-0">
				Get a Free Property Valuation with FindQo.ie Property Valuation Calculator!
			</p>
		</div>

		<!-- Action Button (Right Edge) -->
		<div class="col-auto ps-0">
			<findqo-button
				(click)="onNavigate()"
				[color]="'fg-basic-1'"
				[btnClass]="viewPort.mobile ? 'fs-12px rounded-pill h-32px' : 'rounded-pill h-32px'"
				[label]="'Free Valuation'"
			></findqo-button>
		</div>
	</div>
</div>
