<findqo-sticky-promotion-header></findqo-sticky-promotion-header>
<div
	*ngIf="{
		isHeaderVisible: routeDataType.IS_HEADER_VISIBLE | visible | async,
		isMobileHeaderVisible: routeDataType.IS_MOBILE_HEADER_VISIBLE | visible | async,
		isPlaceAdHeaderVisible: routeDataType.IS_PLACE_AD_HEADER_VISIBLE | visible | async,
		currenRoute: currenRoute$ | async,
		isEmptyHeader: routeDataType.IS_EMPTY_HEADER_VISIBLE | visible | async
	} as vm"
	class="bg-basic-1"
	[class.border-bottom]="(vm.isHeaderVisible || vm.isMobileHeaderVisible) && !vm.isPlaceAdHeaderVisible"
	[ngClass]="{ 'd-none d-lg-block': !vm.isMobileHeaderVisible }"
>
	<!-- Ireland(V2) header -->
	<ng-container>
		<!-- Removed until UI is improved -->

		<!-- <findqo-header-app-install
			*ngIf="vm.currenRoute === appRoute.home && isShowInstallApp"
			(closeInstallApp)="isShowInstallApp = false"
		></findqo-header-app-install> -->

		<findqo-header-v2 *ngIf="vm.isHeaderVisible"></findqo-header-v2>
		<findqo-place-ad-header *ngIf="vm.isPlaceAdHeaderVisible"></findqo-place-ad-header>

		<findqo-empty-header *ngIf="vm.isEmptyHeader"></findqo-empty-header>
	</ng-container>
</div>
