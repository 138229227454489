import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTE } from '@core/constants';
import { IconType } from '@core/enums';
import { IntercomService } from '@core/services/intercom.service';
import { ViewportService } from '@core/services/viewport.service';
import { environment } from '@environments/environment';
import { ButtonType } from '@shared/components/button/button.component';
import { DestroyService } from '@shared/services/destroy.service';
import { PropertyWorthState } from '@core/data-access/states/property-worth.state';
import { MatDialog } from '@angular/material/dialog';
import { RestrictUserDialogComponent } from './restrict-user-dialog/restrict-user-dialog.component';
import { OFFCANVAS_HEADER_SIDENAV } from '@core/constants/offcanvas.constant';
import { HeaderSidenavComponent } from '../header-sidenav/header-sidenav.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'findqo-empty-header',
	templateUrl: './empty-header.component.html',
	styleUrls: ['./empty-header.component.scss'],
	providers: [DestroyService],
})
export class EmptyHeaderComponent implements OnInit {
	constructor(
		public viewport: ViewportService,
		private router: Router,
		private intercomService: IntercomService,
		private propertyWorthState: PropertyWorthState,
		private dialog: MatDialog,
		private ngbOffCanvas: NgbOffcanvas
	) {}

	isPropertyAIProcessing = false;

	defaultAvatar = environment.defaultAvatarImg;

	readonly iconType = IconType;
	readonly buttonType = ButtonType;

	navigateAway(): void {
		if (this.isPropertyAIProcessing) {
			/**
			 * Show popup, to tell user it is disable.
			 */

			this.dialog
				.open(RestrictUserDialogComponent, {
					disableClose: true,
					panelClass: 'restrict-user-dialog',
				})
				.afterClosed()
				.subscribe((res) => {
					if (res === false) {
						this.router.navigate([APP_ROUTE.home]);
					}
				});

			return;
		}

		this.router.navigate([APP_ROUTE.home]);
	}

	showIntercom(): void {
		this.intercomService.showIntercom();
	}

	ngOnInit(): void {
		this.propertyWorthState.isProcessing$.subscribe((res) => {
			this.isPropertyAIProcessing = res;
		});
	}
	onOpenHeaderSidenav(): void {
		this.intercomService.toggelIntercomPopup(true);
		this.ngbOffCanvas.open(HeaderSidenavComponent, OFFCANVAS_HEADER_SIDENAV);
	}
}
