{
  "data": {
    "all": [
      {
        "id": "1",
        "name": "Antrim",
        "reference": "antrim"
      },
      {
        "id": "2",
        "name": "Armagh",
        "reference": "armagh"
      },
      {
        "id": "3",
        "name": "Carlow",
        "reference": "carlow"
      },
      {
        "id": "4",
        "name": "Cavan",
        "reference": "cavan"
      },
      {
        "id": "5",
        "name": "Clare",
        "reference": "clare"
      },
      {
        "id": "6",
        "name": "Cork",
        "reference": "cork"
      },
      {
        "id": "7",
        "name": "Derry",
        "reference": "derry"
      },
      {
        "id": "8",
        "name": "Donegal",
        "reference": "donegal"
      },
      {
        "id": "9",
        "name": "Down",
        "reference": "down"
      },
      {
        "id": "10",
        "name": "Dublin - All",
        "reference": "dublin"
      },
      {
        "id": "34",
        "name": "Dublin 1",
        "reference": "dublin-1"
      },
      {
        "id": "35",
        "name": "Dublin 2",
        "reference": "dublin-2"
      },
      {
        "id": "36",
        "name": "Dublin 3",
        "reference": "dublin-3"
      },
      {
        "id": "37",
        "name": "Dublin 4",
        "reference": "dublin-4"
      },
      {
        "id": "38",
        "name": "Dublin 5",
        "reference": "dublin-5"
      },
      {
        "id": "39",
        "name": "Dublin 6",
        "reference": "dublin-6"
      },
      {
        "id": "40",
        "name": "Dublin 6W",
        "reference": "dublin-6w"
      },
      {
        "id": "41",
        "name": "Dublin 7",
        "reference": "dublin-7"
      },
      {
        "id": "42",
        "name": "Dublin 8",
        "reference": "dublin-8"
      },
      {
        "id": "43",
        "name": "Dublin 9",
        "reference": "dublin-9"
      },
      {
        "id": "44",
        "name": "Dublin 10",
        "reference": "dublin-10"
      },
      {
        "id": "45",
        "name": "Dublin 11",
        "reference": "dublin-11"
      },
      {
        "id": "46",
        "name": "Dublin 12",
        "reference": "dublin-12"
      },
      {
        "id": "47",
        "name": "Dublin 13",
        "reference": "dublin-13"
      },
      {
        "id": "48",
        "name": "Dublin 14",
        "reference": "dublin-14"
      },
      {
        "id": "49",
        "name": "Dublin 15",
        "reference": "dublin-15"
      },
      {
        "id": "50",
        "name": "Dublin 16",
        "reference": "dublin-16"
      },
      {
        "id": "51",
        "name": "Dublin 17",
        "reference": "dublin-17"
      },
      {
        "id": "52",
        "name": "Dublin 18",
        "reference": "dublin-18"
      },
      {
        "id": "53",
        "name": "Dublin 20",
        "reference": "dublin-20"
      },
      {
        "id": "54",
        "name": "Dublin 22",
        "reference": "dublin-22"
      },
      {
        "id": "55",
        "name": "Dublin 24",
        "reference": "dublin-24"
      },
      {
        "id": "56",
        "name": "Dublin (County)",
        "reference": "dublin-county"
      },
      {
        "id": "11",
        "name": "Fermanagh",
        "reference": "fermanagh"
      },
      {
        "id": "12",
        "name": "Galway",
        "reference": "galway"
      },
      {
        "id": "13",
        "name": "Kerry",
        "reference": "kerry"
      },
      {
        "id": "14",
        "name": "Kildare",
        "reference": "kildare"
      },
      {
        "id": "15",
        "name": "Kilkenny",
        "reference": "kilkenny"
      },
      {
        "id": "16",
        "name": "Laois",
        "reference": "laois"
      },
      {
        "id": "17",
        "name": "Leitrim",
        "reference": "leitrim"
      },
      {
        "id": "18",
        "name": "Limerick",
        "reference": "limerick"
      },
      {
        "id": "19",
        "name": "Longford",
        "reference": "longford"
      },
      {
        "id": "20",
        "name": "Louth",
        "reference": "louth"
      },
      {
        "id": "21",
        "name": "Mayo",
        "reference": "mayo"
      },
      {
        "id": "22",
        "name": "Meath",
        "reference": "meath"
      },
      {
        "id": "23",
        "name": "Monaghan",
        "reference": "monaghan"
      },
      {
        "id": "24",
        "name": "Offaly",
        "reference": "offaly"
      },
      {
        "id": "25",
        "name": "Roscommon",
        "reference": "roscommon"
      },
      {
        "id": "26",
        "name": "Sligo",
        "reference": "sligo"
      },
      {
        "id": "27",
        "name": "Tipperary",
        "reference": "tipperary"
      },
      {
        "id": "28",
        "name": "Tyrone",
        "reference": "tyrone"
      },
      {
        "id": "29",
        "name": "Waterford",
        "reference": "waterford"
      },
      {
        "id": "30",
        "name": "Westmeath",
        "reference": "westmeath"
      },
      {
        "id": "31",
        "name": "Wexford",
        "reference": "wexford"
      },
      {
        "id": "32",
        "name": "Wicklow",
        "reference": "wicklow"
      },
      {
        "id": "33",
        "name": "Outside Ireland",
        "reference": "outside-ireland"
      }
    ]
  }
}
