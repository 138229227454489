import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderComponent } from './header.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { HeaderNavItemsModule } from './components/header-nav-items/header-nav-item.module';
import { PlaceAdHeaderComponent } from './components/place-ad-header/place-ad-header.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderV2Component } from './v2/header-v2/header-v2.component';
import { HeaderAccountMenuModule } from './components/header-account-menu/header-account-menu.module';
import { ButtonModule } from '@shared/components/button/button.module';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileDisabledDialogModule } from '@feature/place-ad/components/profile-disabled-dialog/profile-disabled-dialog.module';
import { EmptyHeaderComponent } from './components/empty-header/empty-header.component';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { PropertyAlertDialogModule } from '@feature/general/filters/components/property-alert-dialog/property-alert-dialog.module';
import { StickyPromotionHeaderComponent } from './components/sticky-promotion-header/sticky-promotion-header.component';
import { RestrictUserDialogComponent } from './components/empty-header/restrict-user-dialog/restrict-user-dialog.component';

@NgModule({
	declarations: [
		HeaderComponent,
		HeaderV2Component,
		LogoutDialogComponent,
		PlaceAdHeaderComponent,
		EmptyHeaderComponent,
		StickyPromotionHeaderComponent,
		RestrictUserDialogComponent,
	],
	imports: [
		SharedModule,
		MatDividerModule,
		MatToolbarModule,
		MatMenuModule,
		HeaderNavItemsModule,
		HeaderAccountMenuModule,
		ButtonModule,
		ProfileDisabledDialogModule,
		MatInputModule,
		MatDialogModule,
		MatSelectModule,
		MatAutocompleteModule,
		MatChipsModule,
		MatSelectModule,
		PropertyAlertDialogModule,
	],
	exports: [HeaderComponent],
})
export class HeaderModule {}
