import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class RetryInterceptor implements HttpInterceptor {
	constructor() {}

	private maxRetries = 1; // Maximum retry attempts
	private retryDelay = 1000; // Delay between retries (ms)

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			retry({
				count: this.maxRetries,
				delay: (error, retryCount) => {
					if (error?.status === 0 || error?.status >= 400) {
						if (error?.error?.error?.id === 'invalidEmail') {
							throw error; // Do not retry if email is invalid
						}

						return timer(this.retryDelay); // Delay between retries
					}
					throw error; // Stop retrying for other errors
				},
			}),
			catchError((error: HttpErrorResponse) => {
				console.error('Request failed after retries:', error);
				return throwError(() => error);
			})
		);
	}
}
