import { APP_ROUTE } from '@core/constants';

export const COMPANY_POLICY_LIST = [
	{
		path: APP_ROUTE.about,
		data: {
			title: 'n58',
		},
	},
	{
		path: APP_ROUTE.agentHub.landing,
		data: {
			title: 'n3293',
		},
	},
	{
		path: APP_ROUTE.propertyWorth,
		data: {
			title: 'n4093',
		},
	},
	{
		path: APP_ROUTE.terms,
		data: {
			title: 'n61',
		},
	},
	{
		path: APP_ROUTE.privacyPolicy,
		data: {
			title: 'n62',
		},
	},
	{
		path: APP_ROUTE.cookiePolicy,
		data: {
			title: 'n3124',
		},
	},
];
